/* eslint-disable  max-len,  eol-last,  no-trailing-spaces,  @typescript-eslint/type-annotation-spacing,  @typescript-eslint/no-unused-vars,   */
import {JsonScopedSerializer} from './JsonParser';
import {ErrorHandlerService} from './error-handler.service';
import {JsonScope} from './jsonScope';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {SearchCriteriaForClass} from './model-dto';
import {ShippingCarrier, ShippingTruck} from './model-shipping';
import {Observable, Subject} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable()
export class ShippingCarrierCtrl {
  errorHandlerService: ErrorHandlerService;
  httpService: HttpClient;


 public constructor(httpService: HttpClient, errorHandlerService: ErrorHandlerService) {
    this.httpService = httpService;
    this.errorHandlerService = errorHandlerService;
  }

 public countShippingCarriers(searchCriteria: SearchCriteriaForClass, jsonScope: JsonScope = new JsonScope(false, [])): Observable<number>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<number>();
    this.httpService.post('/api/shipping-carrier/r/count', JsonScopedSerializer.stringify(searchCriteria, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public createObject(entity: ShippingCarrier, jsonScope: JsonScope = new JsonScope(false, [])): Observable<ShippingCarrier>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<ShippingCarrier>();
    this.httpService.post('/api/shipping-carrier/w/', JsonScopedSerializer.stringify(entity, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public deleteObject(id: string): Observable<void>  {
   const subject = new Subject<void>();
    this.httpService.delete<void>('/api/shipping-carrier/w/' + id + '')
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getObject(id: string): Observable<ShippingCarrier>  {
   const subject = new Subject<ShippingCarrier>();
    this.httpService.get('/api/shipping-carrier/r/' + id + '', {responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getShippingCarriers(searchCriteria: SearchCriteriaForClass, pageNumber: number, pageSize: number, jsonScope: JsonScope = new JsonScope(false, [])): Observable<ShippingCarrier[]>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'pageNumber', value: pageNumber.toString()});
  
    queryParamsList.push({name: 'pageSize', value: pageSize.toString()});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<ShippingCarrier[]>();
    this.httpService.post('/api/shipping-carrier/r/get', JsonScopedSerializer.stringify(searchCriteria, jsonScope) , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public updateObject(entity: ShippingCarrier, jsonScope: JsonScope = new JsonScope(false, [])): Observable<ShippingCarrier>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<ShippingCarrier>();
    this.httpService.put('/api/shipping-carrier/w/' + entity.id.split('/')[1] + '', JsonScopedSerializer.stringify(entity, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

}

@Injectable()
export class ShippingTruckCtrl {
  errorHandlerService: ErrorHandlerService;
  httpService: HttpClient;


 public constructor(httpService: HttpClient, errorHandlerService: ErrorHandlerService) {
    this.httpService = httpService;
    this.errorHandlerService = errorHandlerService;
  }

 public countShippingCarrierTrucks(searchCriteria: SearchCriteriaForClass, shippingCarrierID: string, jsonScope: JsonScope = new JsonScope(false, [])): Observable<number>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'shippingCarrierID', value: shippingCarrierID});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<number>();
    this.httpService.post('/api/shipping-truck/r/for-carrier-count', JsonScopedSerializer.stringify(searchCriteria, jsonScope) , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public createObject(entity: ShippingTruck, jsonScope: JsonScope = new JsonScope(false, [])): Observable<ShippingTruck>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<ShippingTruck>();
    this.httpService.post('/api/shipping-truck/w/', JsonScopedSerializer.stringify(entity, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public deleteObject(id: string): Observable<void>  {
   const subject = new Subject<void>();
    this.httpService.delete<void>('/api/shipping-truck/w/' + id + '')
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getShippingCarrierTrucks(searchCriteria: SearchCriteriaForClass, shippingCarrierID: string, pageNumber: number, pageSize: number, jsonScope: JsonScope = new JsonScope(false, [])): Observable<ShippingTruck[]>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'shippingCarrierID', value: shippingCarrierID});
  
    queryParamsList.push({name: 'pageNumber', value: pageNumber.toString()});
  
    queryParamsList.push({name: 'pageSize', value: pageSize.toString()});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<ShippingTruck[]>();
    this.httpService.post('/api/shipping-truck/r/for-carrier-get', JsonScopedSerializer.stringify(searchCriteria, jsonScope) , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public updateObject(entity: ShippingTruck, jsonScope: JsonScope = new JsonScope(false, [])): Observable<ShippingTruck>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<ShippingTruck>();
    this.httpService.put('/api/shipping-truck/w/' + entity.id.split('/')[1] + '', JsonScopedSerializer.stringify(entity, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

}

